import { graphql } from "gatsby"
import React, {  useEffect } from "react"
import "react-input-range/lib/css/index.css"

import Layout from "../Page"
import FilterResult from "../../components/ContentBuilder/FilterResult"
import MainDesignFilter from "../Includes/MainDesignFilter"
import SidebarFilter from "../Includes/SidebarFilter"
import AllList from "../../components/Category/All/list"
import { connect } from "react-redux"
import { getBoardFromLocalStorage } from "../../state/board"

const SearchResultsPage = ({ data: { silverStripeDataObject }, activeFilters, currentBoards, sort, dispatch }) => {
  useEffect(() => {
    dispatch(getBoardFromLocalStorage())
  }, [])

  const {    
    metaTags,
    customCSS
  } = silverStripeDataObject.Page
  const pageData = {
    pageClass: 'SearchResultsPage',
    bodyClass: '',
    pageTitle: '',
    metaTags: metaTags,
    customCSS: customCSS,
  }

  var isSearching = false
  const search = typeof window !== "undefined" ? window.location.search : "/"
  const params = new URLSearchParams(search)
  var keyword = params.get("keyword")
  if (keyword) {
    isSearching = true
  }

  return (
    <Layout pageData={pageData}>
      <div className="container">
        <MainDesignFilter title="Search Results" intro="" page={'homepage'} />
        <div className="wrap-section no-margin">
          <div className="explore-function-content">
            <FilterResult />
          </div>
        </div>

        <div className="wrap-section main-wrap row mt-5 no-padding-mobile">
          <div className="col-lg-3 col-sm-12">
            <SidebarFilter />
          </div>
          <div className="col-lg-9 col-sm-12 pb-5">
            <AllList activeFilters={activeFilters} currentBoards={currentBoards} sort={sort} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  activeFilters: state.filter.active,
  currentBoards: state.board.all,
  sort: state.sort.current
})

export default connect(mapStateToProps, null)(SearchResultsPage)
export const pageQuery = graphql`
  query($link: String!) {
    silverStripeDataObject(link: { eq: $link }) {     
      SilverStripeSiteTree {
        title
      }
      Page {
        metaTags
        customCSS
      }
    }
  }
`
